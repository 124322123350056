import React, { useEffect, useState, useCallback } from "react"
import * as styles from "./list.module.css"
import Pagenation from "components/tools/pagination.js"
//
import Layout from "components/common/layout.js"
//
import { getDateYMD, getThumb, permission } from "utils"
//
import { shallowEqual, useDispatch, useSelector } from "react-redux"
//
import { getCardListAsync } from "store/modules/postReducer.js"
//
import { navigate } from "gatsby"
import queryString from "query-string"

const List = props => {
  const dispatch = useDispatch()
  const getCardList = useCallback(
    (currentPage, pagePostSize, order) =>
      dispatch(getCardListAsync(currentPage, pagePostSize, order)),
    [dispatch]
  )

  const { cardList, cardTotal } = useSelector(
    ({ postReducer: { card } }) => card,
    shallowEqual
  )
  const { page } = queryString.parse(props.location.search)
  const [currentPage, setCurrentPage] = useState(page ? Number(page) : 1)
  const pagePostSize = 5

  useEffect(() => {
    ;(async () => {
      await getCardList(currentPage, pagePostSize)
    })()
  }, [currentPage])

  return (
    <Layout>
      <div>
        <div
          className={"title"}
          style={{ marginTop: "59px", marginBottom: "37px" }}
        >
          <p className={`subtitle`}>오늘의 단어</p>
          <div className={styles.subtitle}>
            하루 한 개, 매일 새로운 단어를 그림으로 배워 보세요.
          </div>
        </div>
        <div className={`colContainer ${styles.main}`}>
          {cardList.map(post => (
            <div
              key={post.illust_no}
              onClick={() => {
                permission(
                  `/visible/card/detail?illust_no=${post.illust_no}&currentPage=${currentPage}&pagePostSize=${pagePostSize}`
                )
              }}
              className={styles.post}
            >
              <img src={getThumb(post.thumb_title_uuid)} />
              {/* <div>
                <div>{getDateYMD(post.registerTime, "YYYY.MM.DD")}</div>
                <div>{post.title}</div>
                <div>{post.introText}</div>
              </div> */}
            </div>
          ))}
        </div>
        <Pagenation
          total={cardTotal}
          pagePostSize={pagePostSize}
          pageSize={10}
          currentPage={currentPage}
          setCurrentPage={setCurrentPage}
        />
      </div>
    </Layout>
  )
}

export default List
